import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CircularProgress from '@material-ui/core/CircularProgress'

import logo from '../assets/logo.png'
import logoCariacica from '../assets/logo-cariacica.png'

import { constants } from '../utils'
import { appService } from '../services'

const description = {
  textAlign: 'center',
  margin: '25px 0',
}

const buttons = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}

const button = {
  width: 'calc(50% - 15px)',
  height: '60px',
}

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '90%',
  maxWidth: '550px',
  padding: 30,
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  box: boxStyle,
  boxLoading: {
    ...boxStyle,
    height: '236px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingMessage: {
    marginTop: '16px',
  },
  logo: {
    maxHeight: '88px',
    maxWidth: '550px',
    margin: '90px auto',
  },
  title: {
    textAlign: 'center',
  },
  description,
  'description-sm': {
    ...description,
    margin: '25px 0 0',
  },
  buttons,
  'buttons-sm': {
    ...buttons,
    flexDirection: 'column-reverse',
  },
  'buttons-single': {
    ...buttons,
    justifyContent: 'center',
  },
  'buttons-single-sm': {
    ...buttons,
    justifyContent: 'center',
    marginTop: '25px',
  },
  button,
  'button-sm': {
    height: '50px',
    marginTop: '25px;',
  },
  'button-single': {
    height: '60px',
  },
}))

const Home = () => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [publicCall, setPublicCall] = useState({})

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const idvLogo = () =>
    process.env.REACT_APP_IDV === 'cariacica' ? logoCariacica : logo

  const tryToFindInfo = async () => {
    const {
      success,
      payload: { info },
    } = await appService.getPublicCallInfo()

    if (success) {
      setPublicCall(info)
    }

    setLoading(!success)
  }

  useEffect(() => {
    tryToFindInfo()
  }, [])

  useEffect(() => {
    window.initialData = undefined
  }, [])

  const renderEmpty = () => (
    <Paper className={classes.boxLoading}>
      <Typography className={classes.loadingMessage} variant="subtitle1">
        Nenhum processo aberto no momento
      </Typography>
    </Paper>
  )

  const renderLoading = () => (
    <Paper className={classes.boxLoading}>
      <CircularProgress size={40} />
      <Typography className={classes.loadingMessage} variant="subtitle1">
        Carregando...
      </Typography>
    </Paper>
  )

  const renderContent = () => (
    <Paper className={classes.box}>
      <Typography className={classes.title} variant="h5">
        {`Chamada Escolar ${publicCall.schoolYearName}`}
      </Typography>

      <Typography
        className={classes[sm ? 'description-sm' : 'description']}
        variant="body1"
      >
        Seja bem-vindo(a) ao portal da Chamada Escolar
      </Typography>

      {publicCall.renewEnrollment ? (
        <div className={classes[sm ? 'buttons-single-sm' : 'buttons-single']}>
          <Button
            className={classes['button-single']}
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(constants.routes.renewEnrollments.request.path)
            }
          >
            Solicitar Renovação de Matrícula (Rematrícula)
          </Button>
        </div>
      ) : (
        <div className={classes[sm ? 'buttons-single-sm' : 'buttons-single']}>
          <Button
            className={classes[sm ? 'button-sm' : 'button']}
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(constants.routes.subscriptions.search.path)
            }
          >
            Consultar Inscrição
          </Button>

          {publicCall.subscribe && (
            <Button
              className={classes[sm ? 'button-sm' : 'button']}
              variant="contained"
              color="primary"
              onClick={() => history.push(constants.routes.instructions.path)}
            >
              Nova Inscrição
            </Button>
          )}
        </div>
      )}
    </Paper>
  )

  const renderAppropriate = () => {
    if (loading) {
      return renderLoading()
    } else if (publicCall.name) {
      return renderContent()
    }

    return renderEmpty()
  }

  return (
    <>
      <div className={classes.root}>
        <img
          className={classes.logo}
          src={idvLogo()}
          alt="Logo Secretaria de Educação"
        />

        {renderAppropriate()}
      </div>
    </>
  )
}

export default Home
